.App {
  text-align: center;
  padding: 10px;
}

.center {
  display: flex;
  justify-content: center;
}

.particles {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
}

.image-result{
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 2vw;
}

.inputs{
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
}

.or{
  font-weight: bold;
  font-size: large;
  margin: 0 5vw;
}

@media only screen and (max-width: 800px) {
  .inputs{
    flex-direction: column;
    align-items: center;
  }

  .or{
    margin: 2vw 0;
  }

  .image-result{
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}