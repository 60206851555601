input[type="file"] {
    display: none;
}

.custom-file-upload {
    border: 1px solid black;
    display: inline-block;
    cursor: pointer;
    width: 20vw;
    color: white;
    background: black;
    padding: .5rem;
    font-size: 20px;
}

@media only screen and (max-width: 800px) {
    .custom-file-upload{
        width: 40vw;
    }
}