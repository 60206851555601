.celebs{
    width: 40vw;
    height: 50vh;
}

@media only screen and (max-width: 800px) {
    .celebs{
        width: 100vw;
        height: auto;
    }
}