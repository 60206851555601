.header{
    height: 10vh;
    width: 100%;
    display: flex;
    justify-content: flex-start;
}

.logo-container {
    width: 70px;
    height: 100%;
    padding: 5px;
}

.logo-container img{
      max-width: 100%;
      max-height: 100%;
}