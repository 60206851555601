.big-wrapper{
  width: 50vw;
  height: 70vh;
  display: flex;
  justify-content: center;
}

.wrapper{
  position: relative;
}

#inputimage{
  max-width: 100%;
  max-height: 100%;
}

.bounding-box {
  position: absolute;
  box-shadow: 0 0 0 3px #149df2 inset;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  cursor: pointer;
}

@media only screen and (max-width: 800px) {
  .big-wrapper{
    height: 50vh;
  }
}